import React, { useEffect } from "react";
import logo from "assets/walisonslogos/imagefullytransparent.png";
import $ from "jquery";
import { Link } from "react-router-dom";

function Navbar() {
  useEffect(() => {
    allFunctionality();
  }, []);
  return (
    <nav class="navbar fixed-top navbar-expand-lg navbar-light p-0 m-0">
      <Link class="navbar-brand logo-area p-0 m-0" to="/">
        <img class="img-fluid" src={logo} alt="" />
      </Link>
      <button
        class="navbar-toggler mt-3"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"> </span>{" "}
      </button>
      <div class="collapse navbar-collapse " id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto mt-2">
          <li class="nav-item dropdown">
            <Link
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown2"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Company{" "}
            </Link>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown1">
              <Link class="dropdown-item" to="/about">
                About Us{" "}
              </Link>
              <Link class="dropdown-item" to="/ourstory">
                Our Story{" "}
              </Link>
              <Link class="dropdown-item" to="/boardofdirectors">
                Leadership Board{" "}
              </Link>
              <Link class="dropdown-item" to="/career">
                Careers{" "}
              </Link>
              <Link class="dropdown-item" to="/achievements">
                Achievements{" "}
              </Link>{" "}
            </div>{" "}
          </li>
          <li class="nav-item dropdown">
            <Link
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown2"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Media Lounge{" "}
            </Link>{" "}
            <div class="dropdown-menu" aria-labelledby="navbarDropdown2">
              <Link class="dropdown-item" to="/newsroom">
                News Room{" "}
              </Link>{" "}
              <Link class="dropdown-item" to="/images">
                Images{" "}
              </Link>
              {/* <Link class="dropdown-item" href="#">
                            Videos
                          </Link> */}{" "}
            </div>{" "}
          </li>
          <li class="nav-item dropdown">
            <Link
              class="nav-link dropdown-toggle"
              id="navbarDropdown2"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Industries{" "}
            </Link>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown2">
              <div className="sub-dropdown-item">
                <li className="sub_dropdown">
                  <Link class="dropdown-item dropdown-toggle">
                    {" "}
                    Flour Mills{" "}
                  </Link>{" "}
                </li>{" "}
                <div className="sub_dropdown-menu border">
                  <Link class="dropdown-item" to="/flourmill">
                    Wali Son 's Flour and General Mills{" "}
                  </Link>{" "}
                </div>{" "}
              </div>
              <div className="sub-dropdown-item">
                <li className="sub_dropdown">
                  <Link class="dropdown-item dropdown-toggle" href="#">
                    Cotton and Oil Mills{" "}
                  </Link>{" "}
                </li>{" "}
                <div className="sub_dropdown-menu border">
                  <Link class="dropdown-item" to="/cottonmill">
                    Ch.Wali Muhammad Son 's (PVT) LTD{" "}
                  </Link>{" "}
                  <Link class="dropdown-item" to="/cottonmill">
                    WaliSon 's Cotton Ginners{" "}
                  </Link>{" "}
                  <Link class="dropdown-item" to="/cottonmill">
                    Muhammadi Oil Industries{" "}
                  </Link>{" "}
                </div>{" "}
              </div>
              <div className="sub-dropdown-item">
                <li className="sub_dropdown">
                  <Link to="#" class="dropdown-item dropdown-toggle">
                    Agro Chemicals{" "}
                  </Link>{" "}
                  <div className="sub_dropdown-menu border">
                    <Link class="dropdown-item" to="/nayasavaira">
                      Naya Savaira{" "}
                    </Link>{" "}
                    <Link class="dropdown-item" to="/agrifarm">
                      WaliSon 's Agri Farms{" "}
                    </Link>{" "}
                  </div>{" "}
                </li>{" "}
              </div>
              <div className="sub-dropdown-item">
                <li className="sub_dropdown">
                  <Link class="dropdown-item dropdown-toggle" href="#">
                    Corporations{" "}
                  </Link>{" "}
                </li>{" "}
                <div className="sub_dropdown-menu border">
                  <Link class="dropdown-item" to="/corporations">
                    WaliSon 's Corporation and Commission Agents{" "}
                  </Link>{" "}
                  <Link class="dropdown-item" to="/corporations">
                    Usman Corporation and Commission Agents{" "}
                  </Link>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </li>
          <li class="nav-item dropdown">
            <Link
              class="nav-link dropdown-toggle"
              to="#"
              id="navbarDropdown2"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Social Responsibilities{" "}
            </Link>{" "}
            <div class="dropdown-menu" aria-labelledby="navbarDropdown2">
              <Link class="dropdown-item" to="/socialresponsibilities">
                Educational Well - Being |
              </Link>{" "}
              <Link class="dropdown-item" to="/socialresponsibilities">
                Production Stewardship{" "}
              </Link>{" "}
              <Link class="dropdown-item" to="/socialresponsibilities">
                Community Well - Being{" "}
              </Link>{" "}
            </div>{" "}
          </li>{" "}
          {/* <li class="nav-item">
            <Link class="nav-link " to="/blogs">
              Blog{" "}
            </Link>{" "}
          </li>{" "} */}
          <li class="nav-item">
            <Link class="nav-link" to="/contactus">
              Contact Us{" "}
            </Link>{" "}
          </li>{" "}
        </ul>{" "}
      </div>{" "}
    </nav>
  );
}
export default Navbar;

const allFunctionality = () => {
  $(".navbar-nav  a").on("click", function () {
    console.log("yahu");
    if (!$(this).hasClass("dropdown-toggle")) {
      $(".navbar-collapse").toggleClass("show");
    }
  });

  // 		/*------------------
  // 			Navigation
  // 		--------------------*/
  $(".nav-switch").on("click", function (event) {
    $(".nav-menu").slideToggle(400);
    event.preventDefault();
  });

  const $dropdown = $(".dropdown");
  const $dropdownToggle = $(".dropdown-toggle");
  const $dropdownMenu = $(".dropdown-menu");
  const showClass = "show";
  var num = 0;

  const sub_dropdown = document.querySelectorAll(".sub_dropdown");
  const sub_dropdownmenu = document.querySelectorAll(".sub_dropdown-menu");

  sub_dropdown.forEach((elem) => {
    elem.addEventListener("click", (e) => {
      console.log(elem, "yahu");
      sub_dropdownmenu.forEach((ele) => {
        if (ele.classList.contains("show")) {
          console.log("if chali");
          ele.classList.remove("show");
          return false;
        }
      });

      if (num == 0) {
        const sub_dropdown_menu =
          e.target.parentNode.parentNode.querySelector(".sub_dropdown-menu");
        console.log(sub_dropdown_menu, "yahu");
        sub_dropdown_menu.classList.add(showClass);
        num = 1;
      } else if (num == 1) {
        const sub_dropdown_menu =
          e.target.parentNode.parentNode.querySelector(".sub_dropdown-menu");
        sub_dropdown_menu.classList.remove(showClass);
        num = 0;
      }
    });
  });

  $(window).on("load resize", function () {
    if (this.matchMedia("(min-width: 768px)").matches) {
      $dropdown.hover(
        function () {
          const $this = $(this);
          $this.addClass(showClass);
          $this.find($dropdownToggle).attr("aria-expanded", "true");
          $this.find($dropdownMenu).addClass(showClass);
        },
        function () {
          const $this = $(this);
          $this.removeClass(showClass);
          $this.find($dropdownToggle).attr("aria-expanded", "false");
          $this.find($dropdownMenu).removeClass(showClass);
        }
      );
    } else {
      $dropdown.off("mouseenter mouseleave");
    }
  });
};
