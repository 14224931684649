import loadable from 'react-loadable';
import LoaderScreen from './Loader';

const Home = loadable({
  loader: () => import(/*webpackChunkName:" Home" */ 'mainSite/pages/Home'),
  loading: () => <LoaderScreen />,
});
const About = loadable({
  loader: () => import(/*webpackChunkName:" About" */ 'mainSite/pages/About'),
  loading: () => <LoaderScreen />,
});
const BoardOfDirector = loadable({
  loader: () =>
    import(
      /*webpackChunkName:" BoardOfDirector" */ 'mainSite/pages/BoardOfDirector'
    ),
  loading: () => <LoaderScreen />,
});
const FlourMill = loadable({
  loader: () => import(/*webpackChunkName:" FlourMill" */ 'flourMill/pages/Home'),
  loading: () => <LoaderScreen />,
});
const Products = loadable({
  loader: () => import(/*webpackChunkName:" Products" */ 'flourMill/pages/Products'),
  loading: () => <LoaderScreen />,
});

const ProductDetail = loadable({
  loader: () =>
    import(/*webpackChunkName:" ProductDetail" */ 'flourMill/pages/ProductDetail'),
  loading: () => <LoaderScreen />,
});
const OurStory = loadable({
  loader: () => import(/*webpackChunkName:" OurStory" */ 'mainSite/pages/OurStory'),
  loading: () => <LoaderScreen />,
});
const ContactUs = loadable({
  loader: () =>
    import(/*webpackChunkName:" ContactUs" */ 'mainSite/pages/ContactUs'),
  loading: () => <LoaderScreen />,
});
const Blogs = loadable({
  loader: () => import(/*webpackChunkName:" Blogs" */ 'mainSite/pages/Blogs'),
  loading: () => <LoaderScreen />,
});
const Blog = loadable({
  loader: () => import(/*webpackChunkName:" Blog" */ 'mainSite/pages/Blog'),
  loading: () => <LoaderScreen />,
});
const Achievements = loadable({
  loader: () =>
    import(/*webpackChunkName:" Achievements" */ 'mainSite/pages/Achievements'),
  loading: () => <LoaderScreen />,
});
const NewsRoom = loadable({
  loader: () =>
    import(/*webpackChunkName:" Achievements" */ 'mainSite/pages/NewsRoom'),
  loading: () => <LoaderScreen />,
});
const Images = loadable({
  loader: () =>
    import(/*webpackChunkName:" Achievements" */ 'mainSite/pages/Images'),
  loading: () => <LoaderScreen />,
});
const CottonMill = loadable({
  loader: () => import(/*webpackChunkName:" Achievements" */ 'cottonMill/Index'),
  loading: () => <LoaderScreen />,
});
const AgriFarm = loadable({
  loader: () => import(/*webpackChunkName:" Achievements" */ 'AgriFarm/Index.js'),
  loading: () => <LoaderScreen />,
});
const Career = loadable({
  loader: () => import(/*webpackChunkName:" Career" */ 'mainSite/pages/Career'),
  loading: () => <LoaderScreen />,
});
const AgroMill = loadable({
  loader: () =>
    import(/*webpackChunkName:" AgroMill" */ 'Agro Chemicals/pages/Home'),
  loading: () => <LoaderScreen />,
});
const AgroProducts = loadable({
  loader: () =>
    import(/*webpackChunkName:" AgroProducts" */ 'Agro Chemicals/pages/Products'),
  loading: () => <LoaderScreen />,
});
const NeyaSwera = loadable({
  loader: () => import(/*webpackChunkName:" NeyaSwera" */ 'nayaSavaira/Index.js'),
  loading: () => <LoaderScreen />,
});
const Responsibilities = loadable({
  loader: () =>
    import(/*webpackChunkName:" NeyaSwera" */ 'responsibilities/index.js'),
  loading: () => <LoaderScreen />,
});
const Corporations = loadable({
  loader: () => import(/*webpackChunkName:" NeyaSwera" */ 'corporations/Index.js'),
  loading: () => <LoaderScreen />,
});

const routes = [
  { path: '/', component: Home },
  { path: '/about', component: About },
  { path: '/boardofdirectors', component: BoardOfDirector },
  { path: '/blogs', component: Blogs },
  { path: '/blog', component: Blog },
  { path: '/career', component: Career },
  { path: '/achievements', component: Achievements },
  { path: '/newsroom', component: NewsRoom },
  { path: '/images', component: Images },
  { path: '/contactus', component: ContactUs },
  { path: '/ourstory', component: OurStory },
  { path: '/flourmill', component: FlourMill },
  { path: '/flourmill/products', component: Products },
  { path: '/flourmill/products/product', component: ProductDetail },
  { path: '/cottonmill', component: CottonMill },
  { path: '/agrifarm', component: AgriFarm },
  { path: '/agrochemicals', component: AgroMill },
  { path: '/agroproducts', component: AgroProducts },
  { path: '/nayasavaira', component: NeyaSwera },
  { path: '/socialresponsibilities', component: Responsibilities },
  { path: '/corporations', component: Corporations },
];

export default routes;
