import React from "react";

function Footer() {
  return (
    <footer class="footer-area">
      <div class="footer-top bg-1">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="footer-widget footer-logo">
                <h4 class="widget-title">Company</h4>
                <h6 style={{ color: "#39b54a" }}>
                  WALISON'S Cotton Ginners(Head Office).
                </h6>
                <p>
                  {/* Lorem Ipsum available but the arran majority have suffered
                  alteration in some form by injected humour. */}
                </p>
                <h4 style={{ color: "white" }}>Working Hours: </h4>
                <span>Mon - Sat 8:00 - 18:00 (Sunday off)</span>
                <ul className="social-links">
                  <li>
                    <a href="#">
                      <i className="fa fa-whatsapp"></i>
                    </a>
                  </li>

                  <li>
                    <a href="https://www.facebook.com/walisonsgroupofindustries">
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  {/* <li>
                    <a href="#">
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-12">
              <div class="footer-widget footer-contact">
                <h4 class="widget-title">GET IN TOUCH</h4>
                <ul>
                  <li>
                    <i class="fa fa-home"></i> Wali Sons , RahimYarKhan
                  </li>
                  <li>
                    <i class="fa fa-phone"></i> +92-332-563670
                  </li>

                  <li>
                    <i class="fa fa-envelope"></i>{" "}
                    <a href="" class="__cf_email__">
                      info@walisonsgroup.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-2 col-sm-6 col-12">
              <div class="footer-widget footer-menu">
                <h4 class="widget-title">Services</h4>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/about">About Us</a>
                  </li>
                  {/* <li>
                    <a href="">Industires</a>
                  </li> */}
                  {/* <li>
                    <a href="#">Blog</a>
                  </li> */}
                  <li>
                    <a href="/contactus">Contact</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-12">
              <div class="footer-widget newsletter">
                <h4 class="widget-title">Working Hours</h4>
                <p>Monday - 10:00 - 18:00</p>
                <p>Tuesday - 10:00 - 18:00</p>
                <p>Wednesday - 10:00 - 18:00</p>
                <p>Thursday - 10:00 - 18:00</p>
                <p>Friday - 10:00 - 18:00</p>
                <p>Saturday - 10:00 - 18:00</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-bootem">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <p>
                &copy; Copyright
                <script>document.write(new Date().getFullYear());</script>
                <span> WaliSons 2021 All rights reserved</span>
              </p>
              <p>
                <i class="fa fa-heart-o mx-2" aria-hidden="true"></i> Powered by
                <a
                  href="https://www.guru.com/freelancers/abdullahzulfiqar"
                  className="pl-1 ryk"
                >
                  {" "}
                  RYKSOFT
                </a>
                <a className="pl-1" href="https://www.facebook.com/ryksoft">
                  <span className="pl-2">
                    <i className="fa fa-facebook"></i>
                  </span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
