import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop({ children }) {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    if (pathname != '/contact') window.scrollTo(0, 0);
  }, [pathname]);

  return children;
}

export default ScrollToTop;
