import React from 'react';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import './css/Loader.css';

function LoaderScreen() {
  return (
    <div className='loader_screen'>
      <Loader type='Oval' color='white' height={80} width={80} />
    </div>
  );
}

export default LoaderScreen;
