import React from "react";
import { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import routes from "routes";
import Navbar from "mainSite/component/Navbar";
import Footer from "mainSite/component/Footer";
// import ComingSoon from "./ComingSoon";   
import AOS from "aos";
import "aos/dist/aos.css";
import ScrollToTop from "./ScrollToTop";

function App() {
  // const [visible, setVisible] = React.useState(true);

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <ScrollToTop>
      {/* {visible && <ComingSoon setVisible={setVisible} />} */}
      <Navbar />
      <Switch>
        {routes.map((prop, key) => (
          <Route exact path={prop.path} component={prop.component} />
        ))}
      </Switch>
      <Footer />
    </ScrollToTop>
  );
}

export default App;
